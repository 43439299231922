

import './CvComponent.css'

export const CvComponent = () => {

  return (
    <div className='cv-container'>
      <a href='CV-David-Fernandez-Morilla.pdf' download='cv-david-fernandez-morilla'>Descarga mi CV</a>
    </div>
  )
}
